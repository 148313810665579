.faq {
   position: relative;
   left: 10%;
   width: 80%;
}

.accordion {
  background-color: #eae6e5;
  color: #011627;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: rgba(1, 22, 39, 0.2);
}

.accordion:after {
  content: '\002B';
  color: rgba(1, 22, 39, 1.0);;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-line::after {
  content: "";
  display: block;
  border-bottom: 1px solid #eae6e5; 
  margin-top: 10px; 
}