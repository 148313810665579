.ways-to-give {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
}

.ways-to-give p {
  margin-left: 20%;
  margin-right: 20%;
}

.content {
  display: flex;
  justify-content: space-evenly;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-left: 15%;
  margin-right: 15%;
}

.content p {
  width: 50%; /* each paragraph taking up half the width of the container */
  box-sizing: border-box; /* to include any padding or borders in the total width */
}

.address {
  margin-left: 100px;
}

.address strong {
  font-weight: bold;
}

.address span {
  font-weight: lighter;
}

.wtg-bgimg {
  background-image: url('../../assets/images/repeat-bg2.png');
  background-position: top center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  /* background-attachment: fixed; */
}

