.AdoptionForm {
  width: 100%;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcc7be;
}


/* IntakeForm.css */
.AdoptionForm select,
.AdoptionForm input[type="text"]{
  width: 100%; /* Sets the width of the fields to occupy full available space. Adjust as needed. */
  height: 30px;
}

.AdoptionForm input[type="number"] {
  width: 20%;
}
 

.AdoptionForm label {
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
}

.AdoptionForm textarea {
    min-height: 150px;    /* Adjust as needed */
    max-height: 150px;    /* Adjust as needed */
}

.date {
    width: 70%;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
}

.checkboxes label {
  width: 70%; /* Adjust this for more columns */
  box-sizing: border-box;
  padding-right: 10px;
  text-align: left;
}

.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.required-text {
  color: red;
  font-size: small;
}

.note {
  font-size: small;
}

.button-container {
  margin-top: 30px;
}

.progress-div {
    width: 100%;
    background-color: #f3f3f3;
    margin-top: 10px;
    border-radius: 13px;
    padding: 3px;
}

.progress-bar {
    height: 30px; /* Change to increase or decrease the thickness of the progress bar */
    width: calc((100% / 6) * var(--current-page)); /* Calculates the width based on current page */
    background-color: #5b9279;
    border-radius: 10px;
}

.save-sub-button {
  margin-top: 10px;
}

.next-prev-button {
  background: #007BFF; 
  color: white;
  font-weight: bold;
  border-radius: 12px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .checkboxes {
    font-size: 8px;
  }
  .checkboxes label {
    width: 100%; /* Adjust this for more columns */
    box-sizing: border-box;
    padding-right: 3px;
  }
  .checkboxGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3px;
  }

}

