@font-face {
    font-family: 'Merriweather';
    src: url('./assets/fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Q';
  src: url('./assets/fonts/AustieBostKittenKlub.ttf') format('truetype');
}

.q {
  font-family: 'Q' !important;
  font-size: 20px;
}

@media screen and (min-width: 1400px) {
  .q {
    font-size: 22px; /* Set the size appropriate for very large screens */
  }
}

@media screen and (max-width: 600px) {
  .q {
    font-size: 17px;
  }
}



body {
    font-family: 'Merriweather', Fallback, sans-serif;
    color: rgba(1, 22, 39, 1.0);
}

.all-a:link, .all-a:visited {
  color: #19ba0b; 
  text-decoration: none;
}

.new-page {
  margin-top: 12%;
  text-align: center;
}



