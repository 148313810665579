.about-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-wrapper {
    width: 70%;
    text-align: left;
}
  
