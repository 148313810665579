.volunteer-bgimg {
    background-image: url('../../assets/images/Logo-bg2.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 500px;
  }

.content-wrapper {
    width: 70%;
    text-align: left;
}

.volunteer-div {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-left: 15%;
    margin-right: 15%;
    justify-content: left;
}