.rounded-button {
    display: inline-block;
    padding: 10px 20px;
    color: rgba(1, 22, 39, 1.0);
    border: 2px solid rgba(1, 22, 39, 1.0);
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.6s ease, color 0.6s ease;
}

.rounded-button:hover {
    color: #eae6e5;
    background-color: rgba(1, 22, 39, 1.0);;
}