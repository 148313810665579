.linknav {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 10px; */
    font-weight: bold;
}

  .rounded-section {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-around;
    background-color: #011627;
    border-radius: 20px; /* Adjust as needed */
    padding: 10px;
  }

  .first {
    margin-left: 30px;
  }

  .last {
    margin-right: 30px;
  }

  .first-3 {
    margin-left: 50px;
  }

  .last-3 {
    margin-right: 50px;
  }
  
  .separator {
    height: 50px; /* Adjust as needed */
    width: 1px;
    background-color: #eae6e5; /* Adjust as 8fcb9b needed */
  }

.linknav a:link, .linknav a:visited {
    color: #eae6e5; /* unvisited links */
    text-decoration: none; /* removes underline */
}

.linknav a:visited {
    /* color: darkslategray;  */
    color: #eae6e5;
}
  
/* Media query for mobile devices */
@media (max-width: 768px) {
    .rounded-section {
        flex-direction: column;
        align-items: center;
    }
    .separator {
        width: 80%; /* Adjust as needed */
        height: 1px;
        margin: 10px 0; /* Add some margin to space out the elements */
    }
    .first {
      margin-left: 0px;
    }
  
    .last {
      margin-right: 0px;
    }
  
    .first-3 {
      margin-left: 0px;
    }
  
    .last-3 {
      margin-right: 0px;
    }
}