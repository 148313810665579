.social img {
    height: 45px;
    width: 45px;
}

.social {
    margin-top: 15px;
    text-align: center;
}

.social a {
    padding: 5px;
}
