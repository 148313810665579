.foster-bgimg {
    background-image: url('../../assets/images/Logo-bg2.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.content-wrapper {
    width: 70%;
    text-align: left;
}

.foster-div {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-left: 15%;
    margin-right: 15%;
    justify-content: left;
}

.foster-div ul {
    list-style-type: disc;
    display: block;
    padding-left: 20px;
}