nav {
    width: 100%;
    background-color: rgba(1, 22, 39, 0.3);
    position: fixed;
    top: 0;
    z-index: 100;
    /* margin-bottom: 10px; */
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: calc(10px + 2vmin);
}

nav:hover {
    background-color: rgba(1, 22, 39, 1.0);
}

ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
}

li {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;   
    padding-bottom: 10px;
    transition: background-color .3s; 
}

li:hover {
    background-color: #eae6e5;
}

li a {
    text-decoration: none;
    color: #eae6e5;
    padding: 10px 0; /* Ensures the clickable area is the full height */
    display: block; /* Makes the entire li area clickable, not just the text */
}

li a:hover {
    color: rgba(1, 22, 39, 1.0);
}

/* This class will be used for the Login link */
li.float-right {
    margin-left: auto;
    /* display: none; */
}

.hamburger {
    float: right;
    background-color: rgba(1, 22, 39, 1.0);
    color: #eae6e5;
    border: none;
    font-size: 30px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(1, 22, 39, 0.2);
    margin-left: -10px;
    margin-top: 5px;
    z-index: 1;
    background-color: rgba(1, 22, 39, 1.0);
    width: 100%;
}

.dropdown:hover .dropdown-content { 
    display: block;
}

.navbar-mobile {
    flex-direction: column;
}

.dropdown-content a:hover {
    background-color: #eae6e5;
    color: rgba(1, 22, 39, 1.0);;
}

.signup {
    font-size: 16px;
    align-items: center;
    padding: 15px 5px;
}

@-moz-document url-prefix() {
    .q {
        font-size: 16px; /* replace with your desired font size */
    }
}

@media only screen and (max-width: 768px) {
    .dropdown:hover .dropdown-content { 
        display: none;
    }
    nav {
        background-color: rgba(1, 22, 39, 1.0);;
    }
    .signup {
        font-size: calc(10px + 2vmin);
        /* display:none; */
    }
    li.float-right {
        margin-left: 0%;
    }
}

@media only screen and (min-width: 768px) {
    .dropdown-link { 
        display: none;
    }
}