.home-bgimg {
    background-image: url('../../assets/images/Logo-bg2.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .home-header {
    height: auto;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-header h1 {
  font-size: 60px;
}

.home-header h2 {
  font-size: 50px;
}

@media (max-width: 768px) {
    .home-bgimg {
      /* background-size: auto; */
      display: none;
      background-color: #eae6e5;
    }
}