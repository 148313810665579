.my-button {
    cursor: pointer;
}

/* This will only take effect for screens with a max-width of 600px */
@media screen and (max-width: 600px) {
  .my-button {
    font-size: larger; /* or use px, em, etc. as needed */
    padding: 15px 20px; /* adjust as needed */
  }
}