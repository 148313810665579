.adopt-bgimg {
    background-image: url('../../assets/images/Logo-bg2.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.content-wrapper {
    width: 70%;
    text-align: left;
}

.adopt-div {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-left: 15%;
    margin-right: 15%;
    justify-content: left;
}

.adopt-div ul {
    list-style-type: disc;
    display: block;
    padding-left: 20px;
}

.right-align-div {
    width: 30%; /* adjust as needed */
    position: absolute;
    right: 0;
}

.adopt-buttons {
    display: flex;
}

