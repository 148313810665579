.bgimg {
  background-image: url('../../assets/images/cat_group.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.home-header {
    min-height: 330px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .bgimg {
    background-size: auto;
    /* background-position: top center; */
  }
}

/* .tit-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
    color: unset;
    text-shadow: -0.5px -0.5px 0 white,  
                 0.5px -0.5px 0 white,
                -0.5px 0.5px 0 white,
                 0.5px 0.5px 0 white;   
}

.tit-header p {
    color: unset;
    font-size: 16px;
} */