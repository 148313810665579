.contact-form {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  width: 50%;
  margin-bottom: 10px;
  border-radius: 10px; /* Adjust this value to your liking */
}

.contact-form input {
  height: 40px;
}

.contact-form textarea {
  height: 80px;
  border-radius: 10px; /* Adjust this value to your liking */
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* This will only take effect for screens with a max-width of 600px */
@media screen and (max-width: 600px) {
  .contact-form textarea {
    height: 120px;
  }
}