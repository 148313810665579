.pf-div {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    text-align: center;
}

.pf-div p {
    margin-left: 20%;
    margin-right: 20%;
  }

.pf-btn {
  padding: 10px;
}