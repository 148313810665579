.followus-bgimg {
    background-image: url('../../assets/images/bottom-bg.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 500px;
  }

.followus {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.followus h1 {
    margin-top: -20px;
    font-size: 60px;
}
  
  .followus p {
    margin-left: 20%;
    margin-right: 20%;
  }

  .followus img {
    margin-top: -50px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .social-buttons {
    z-index: 1;
    margin-top: -10px;
  }

  @media (max-width: 600px) {
    .social-media-icon {
      width: 33px !important;
      height: 33px !important;
    }
  }

@media (max-width: 400px) {
  .social-media-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

@media (max-width: 300px) {
  .social-media-icon {
    width: 20px !important;
    height: 20px !important;
  }
}