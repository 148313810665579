footer {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

footer p {
    font-size: 12px;
    background-color: #eae6e5;
    padding: 8px;
}
