.welcome-bgimg {
    background-image: url('../../assets/images/Logo-bg2.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 500px;
  }

.welcome-div {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    text-align: center;
}

.welcome-div p {
    margin-left: 20%;
    margin-right: 20%;
  }